import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PostListing from '../components/PostListing';
import DeprecatedSEO from '../components/SEO';
import { IndexQueryQuery } from '../graphqlTypes';

const BlogPage: React.FC<{ data: IndexQueryQuery }> = ({ data }) => {
  return (
    <Layout>
      <main>
        <Helmet title={`Blog | ${data.site?.siteMetadata?.title}` || ''} />
        {/* This breaks everything now. TODO: Fix SEO here */}
        {/* <SEO /> */}
        <PostListing postEdges={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            categories
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
